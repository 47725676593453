import React from 'react'
import ServiceSidebarz from './ServiceSidebarz'
import details1z from '../../assets/images/services/services-details1z.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentz = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1z} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">BEX/BW </span>
                            <h3>About this Service</h3>
                            <p>In a complex world, organizations sometimes need to access and analyze deeply embedded data from multiple sources and platforms. Business Objects Reporting helps businesses to access these multiple platform data, both numeric and text, and to translate it into valuable information. </p>
                            <p>Our BO and BODS services provide you intelligent reporting solutions from real-time metrics to in-depth analysis. It helps your company optimize operations. Complex data are interpreted for trends and outliers and predictable patterns that help enhance key business processes and put in context numeric visualizations.</p>
                            <p>Our SAP Business Objects  solutions can empower your teams to achieve remarkable results by allowing anyone in your organization self-service access to relevant information and by helping business users transform their decision-making by providing fact-based, quality information regardless of where the data resides. SAP Business Objects BI solutions provide comprehensive functionality for the following:</p>
                            <li>Reporting and analysis</li>
                            <li>Dashboards</li>
                            <li>Data exploration</li>
                            <li>Mobile</li>
                            <li>BI platform</li>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Intelligent reporting</li>
                                            <li>Multi-dimensional data analysis</li>
                                            <li>Integrated planning</li>
                                            <li>Real-Time Insights</li>
                                            <li>Information Broadcasting</li>
                                            <li>Simplified, personalized, and dynamic information</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>In times of economic uncertainty, Our BO / BODs solutions curriculum can help you: obtain clarity and insight into your data to make effective data-centric business decisions, empower business users by providing them trusted information that will help them win in this economy, maintain operational sustainability, and financial growth through improved streamlining of operations,
                            Our focus is on bringing the highest quality BO resources to you, whether you need spot consulting or need to stand up a whole team that will create dynamic reports for you. Based on these reports/dashboards your company will be able to make better, more informed decisions with access to real-time insights on a single, scalable platform.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>

                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>BW</span></li>
                                <li><span>BEx query designer</span></li>
                                <li><span>Excel</span></li>
                                <li><span>OLAP for Analysis</span></li>
                                <li><span>Cloud Analytics</span></li>
                                <li><span>Leonardo</span></li>
                                <li><span>Information Design Tools</span></li>
                                <li><span>WEBI</span></li>
                                <li><span>Crystal Reports</span></li>
                                <li><span>Universe Designer</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarz />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentz